import React, { useState, useEffect, useRef } from 'react';
import { Button, Typography } from '@mui/material';
import supabase from '../supabaseClient';
import { Checkbox, Alert, Snackbar } from '@mui/material';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from './Droppable';
import {
    TextField,
    List,
    ListItem,
    IconButton
} from '@mui/material';
import Delete from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { v4 as uuidv4 } from 'uuid';
import { SendToThings } from './SendToThings';

export function ShoppingList({ listId }) {
    const [listItems, setListItems] = useState([]);
    const [editingId, setEditingId] = useState(null);
    const [editingValue, setEditingValue] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [deletedItem, setDeletedItem] = useState({ item: null, index: -1 });
    const [showList, setShowList] = useState(false);

    const editingRef = useRef(null);

    useEffect(() => {
        if (editingRef.current) {
            editingRef.current.focus();
        }
    }, [editingId]);


    const handleEdit = (id) => {
        const item = listItems.find(item => item.id === id);
        setEditingId(id);
        setEditingValue(item.name);
    };

    const handleUpdate = async (id) => {
        const updatedItems = listItems.map(item => item.id === id ? { ...item, name: editingValue } : item
        );
        setListItems(updatedItems);
        setEditingId(null);
        setEditingValue("");

        try {
            const { error } = await supabase.from('lists_shopping_list').update({ ingredient_json: updatedItems }).eq('list_id', listId);
            if (error) throw error;
        } catch (error) {
            console.error("Error updating item:", error);
        }
    };

    const handleCheckboxChange = async (id) => {
        // Update local state
        const updatedItems = listItems.map(item => item.id === id ? { ...item, checkbox: !item.checkbox } : item
        );
        setListItems(updatedItems);

        // Update database
        try {
            const { error } = await supabase.from('lists_shopping_list').update({ ingredient_json: updatedItems }).eq('list_id', listId);
            if (error) throw error;
        } catch (error) {
            console.error("Error updating checkbox:", error);
        }
    };

    useEffect(() => {
        const fetchItems = async () => {
            if (!listId) return;
    
            const { data } = await supabase.from('lists_shopping_list').select('ingredient_json').eq('list_id', listId);
            if (data && data[0]?.ingredient_json) {
                setListItems(data[0].ingredient_json);
                setShowList(true);  // Show the list if ingredient_json is not null
            } else {
                setShowList(false); // Hide the list if ingredient_json is null or not fetched
            }
        };
        fetchItems();
    }, [listId]);

    const onDragEnd = async (result) => {
        if (!result.destination) return;

        const reorderedItems = [...listItems];
        const [removed] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, removed);

        setListItems(reorderedItems);

        try {
            const { error } = await supabase.from('lists_shopping_list').update({ ingredient_json: reorderedItems }).eq('list_id', listId);
            if (error) throw error;
        } catch (error) {
            console.error("Error updating order:", error);
        }
    };


    const deleteItem = async (id) => {
        const indexToDelete = listItems.findIndex(item => item.id === id);
        const itemToDelete = listItems[indexToDelete];

        const updatedItems = listItems.filter(item => item.id !== id);
        setListItems(updatedItems);
        setSnackbarOpen(true);

        // Store the deleted item and its index for undo purposes
        setDeletedItem({ item: itemToDelete, index: indexToDelete });

        try {
            const { error } = await supabase.from('lists_shopping_list').update({ ingredient_json: updatedItems }).eq('list_id', listId);
            if (error) throw error;
        } catch (error) {
            console.error("Error deleting item:", error);
        }
    };


    const addNewItem = async (itemName = "") => {
        if (!itemName || itemName.trim() === "") {
            itemName = ""; // Set a default value if itemName is not provided or is undefined
        }

        const newItem = {
            id: uuidv4(),
            name: itemName || '',
            checkbox: false
        };

        // Prepend newItem to the beginning of the list
        const updatedItems = [newItem, ...listItems];
        setListItems(updatedItems);

        try {
            const { error } = await supabase.from('lists_shopping_list').update({ ingredient_json: updatedItems }).eq('list_id', listId);
            if (error) throw error;
        } catch (error) {
            console.error("Error adding new item:", error);
        }

        return newItem.id;
    };

    const undoDelete = async () => {
        if (deletedItem.item && deletedItem.index > -1) {
            const restoredList = [...listItems];
            restoredList.splice(deletedItem.index, 0, deletedItem.item);
            setListItems(restoredList);

            // Update the database
            try {
                const { error } = await supabase.from('lists_shopping_list').update({ ingredient_json: restoredList }).eq('list_id', listId);
                if (error) throw error;
            } catch (error) {
                console.error("Error restoring item:", error);
            }
        }

        closeSnackbar();
    };

    const closeSnackbar = () => {
        setSnackbarOpen(false);
        setDeletedItem(null);
    };

    return (
        <div style={{}}>
            
        {showList && (  // Only render this block if showList is true
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='overline' sx={{ fontFamily: 'IBM Plex Mono', textTransform: 'lowercase', fontSize: '1.1rem', letterSpacing: 0 }}>shopping list</Typography>
                <Button sx= {{fontFamily: 'IBM Plex Mono'}} onClick={() => {
                    const newItemId = addNewItem();
                    setEditingId(newItemId);
                }
                }>
                    <AddIcon /> Add item
                </Button>        

            </div>)}

            <DragDropContext onDragEnd={onDragEnd}>
                <StrictModeDroppable droppableId="droppable">
                    {(provided) => (
                        <List sx={{ padding: 0 }} ref={provided.innerRef} {...provided.droppableProps}>
                            {listItems.map((item, index) => (
                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                                ...provided.draggableProps.style,
                                                backgroundColor: snapshot.isDragging ? '#cccccc55' : 'white',
                                                border: snapshot.isDragging ? '1px solid #ccc' : '',
                                                color: snapshot.isDragging ? '#777' : '',
                                                borderRadius: '.5em'
                                            }}
                                        >
                                            <ListItem sx={{ padding: '.25em .5em .25em .5em', marginBottom: 0, borderRadius: '.5em' }} onClick={() => handleEdit(item.id)}>
                                                <Checkbox
                                                    checked={item.checkbox}
                                                    onClick={(e) => e.stopPropagation()} // Stop click event propagation
                                                    onChange={(e) => {
                                                        handleCheckboxChange(item.id);
                                                    }}
                                                    sx={{ alignSelf: 'flex-start', padding: '.25em .5em 0 0' }} />
                                                <div style={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
                                                    {editingId === item.id ? (
                                                        <TextField
                                                            multiline
                                                            variant="standard" // or "filled"
                                                            sx={{
                                                                '& .MuiInputBase-input': {
                                                                    padding: 0
                                                                },
                                                                '& .MuiInput-underline:before': {
                                                                    borderBottom: 'none' // remove underline for standard variant
                                                                },
                                                                '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                                                    borderBottom: 'none' // remove hover effect for standard variant
                                                                },
                                                                alignSelf: 'flex-start',
                                                                fontFamily:'IBM Plex Mono'
                                                            }}
                                                            ref={editingId === item.id ? editingRef : null}
                                                            fullWidth
                                                            value={editingValue}
                                                            onChange={(e) => setEditingValue(e.target.value)}
                                                            onKeyPress={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    handleUpdate(item.id);
                                                                }
                                                            }}
                                                            autoFocus
                                                            onBlur={() => {
                                                                handleUpdate(item.id);
                                                            }} />
                                                    ) : (
                                                        <span
                                                            onClick={() => handleEdit(item.id)}
                                                            style={{
                                                                cursor: 'pointer',
                                                                flexGrow: 1,
                                                                // marginRight: '10px', 
                                                                whiteSpace: 'pre-wrap',
                                                                padding: '.25em 0 ',
                                                                fontFamily: 'IBM Plex Mono',
                                                                fontSize:'14px'
                                                            }}
                                                        >
                                                            {item.name}
                                                        </span>
                                                    )}
                                                    <IconButton
                                                        edge="end"
                                                        type="button"
                                                        sx={{ flexShrink: 1, display: 'flex', margin: '0 0 0 .25em', padding: '.25em', alignSelf: 'flex-start' }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            deleteItem(item.id);
                                                        }}
                                                    >
                                                        <Delete sx={{ flexShrink: 1, alignSelf: 'flex-start' }} />
                                                    </IconButton>
                                                </div>
                                            </ListItem>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </List>
                    )}
                </StrictModeDroppable>
            </DragDropContext>
            {showList && (<>
            <hr style={{opacity:'.4'}}/>
            <SendToThings listItems={listItems}/></>)}

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={5000}
                onClose={closeSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert
                    onClose={closeSnackbar}
                    severity="success"
                    variant="filled"
                    action={<Button
                        color="secondary"
                        size="small"
                        onClick={undoDelete}
                        style={{ color: 'white' }} // Add this line
                    >
                        UNDO
                    </Button>}
                >
                    Item deleted
                </Alert>
            </Snackbar>
        </div>
    );
}
