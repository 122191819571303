// DynamicFilter.jsx
import React, { useState, useEffect } from "react";
import supabase from "../supabaseClient";
import { Autocomplete, TextField, Paper } from "@mui/material";

function DynamicFilter({ selectedFilters, updateFilters }) {
  const [items, setItems] = useState([]);
  const [autocompleteValue, setAutocompleteValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [filterType, setFilterType] = useState("Book");

  useEffect(() => {
    const fetchItems = async () => {
      if (filterType === "Time") {
        setItems([]);
      } else {
        const { data, error } = await supabase
          .from("books")
          .select(filterType === "Book" ? "name" : "author");
        if (error) {
          console.error(`Error fetching ${filterType.toLowerCase()}s:`, error);
        } else {
          const mappedItems =
            filterType === "Book"
              ? data.map((item) => item.name)
              : [
                  ...new Set(
                    data
                      .filter((item) => item.author !== null)
                      .map((item) => item.author)
                  ),
                ]; // Remove duplicates
          setItems(mappedItems);
        }
      }
    };

    fetchItems();
  }, [filterType]);

  const filteredItems = items.filter(
    (item) =>
      !selectedFilters.some(
        (filter) => filter.type === filterType && filter.value === item
      )
  );

  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      {filterType !== "Time" ? (
        <Autocomplete
          style={{ width: "60%", fontFamily: "IBM Plex Mono, monospace" }} // IBM Plex Mono font
          options={filteredItems}
          value={autocompleteValue}
          inputValue={inputValue}
          onChange={(event, newValue) => {
            updateFilters(filterType, newValue);
            setAutocompleteValue(null);
            setInputValue("");
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={filterType === "Book" ? "book title" : "author"} // Lowercase labels
              variant="outlined"
              fullWidth
              sx={{
                fontFamily: "'IBM Plex Mono', monospace",
                "& .MuiOutlinedInput-root": {
                  fontFamily: "'IBM Plex Mono', monospace",
                },
                "& .MuiInputLabel-outlined": {
                  fontFamily: "'IBM Plex Mono', monospace",
                },
              }}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <select
                      value={filterType}
                      onChange={(e) => setFilterType(e.target.value)}
                      style={{
                        border: "none",
                        background: "transparent",
                        marginRight: "10px",
                        fontFamily: "'IBM Plex Mono', monospace", // IBM Plex Mono font
                      }}
                      onMouseDown={(e) => e.stopPropagation()}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <option value="Book">book</option>
                      <option value="Author">author</option>
                      <option value="Time">time</option>
                    </select>
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
            />
          )}
          PaperComponent={({ children }) => (
            <Paper
              sx={{
                backgroundColor: "none",
                border: "1px solid #ccc",
                fontFamily: "'IBM Plex Mono', monospace",
                fontSize: '14px' // IBM Plex Mono font
              }}
              elevation={0}
            >
              {children}
            </Paper>
          )}
        />
      ) : (
        <TextField
          style={{ width: "60%", fontFamily: "IBM Plex Mono, monospace" }} // IBM Plex Mono font
          sx={{
            fontFamily: "'IBM Plex Mono', monospace",
            "& .MuiOutlinedInput-root": {
              fontFamily: "'IBM Plex Mono', monospace",
            },
            "& .MuiInputLabel-outlined": {
              fontFamily: "'IBM Plex Mono', monospace",
            },
          }}
          label="max time (minutes)" // Lowercase label
          variant="outlined"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && inputValue && !isNaN(inputValue)) {
              updateFilters("Time", inputValue);
              setInputValue("");
            }
          }}
          InputProps={{
            startAdornment: (
              <>
                <select
                  value={filterType}
                  onChange={(e) => setFilterType(e.target.value)}
                  style={{
                    border: "none",
                    background: "transparent",
                    marginRight: "10px",
                    fontFamily: "IBM Plex Mono, monospace", // IBM Plex Mono font
                  }}
                  onMouseDown={(e) => e.stopPropagation()}
                  onClick={(e) => e.stopPropagation()}
                >
                  <option value="Book">book</option>
                  <option value="Author">author</option>
                  <option value="Time">time</option>
                </select>
              </>
            ),
          }}
        />
      )}
    </div>
  );
}

export default DynamicFilter;
