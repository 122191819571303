import React from 'react';
import SearchIcon from '@mui/icons-material/Search';

function SearchBar({ query, setQuery }) {

    const [localQuery, setLocalQuery] = React.useState(query);
    const [isFocused, setIsFocused] = React.useState(false);

    return (
        <form 
            style={{ 
                fontFamily: 'IBM Plex Mono, monospace', 
                display: 'flex', 
                border: '0.0625em solid #ccc', 
                borderRadius: '0.3125em', 
                position: 'relative' 
            }}
            onSubmit={e => {
                e.preventDefault();
                setQuery(localQuery); // Update the URL based on the current localQuery
            }}
        >               
            <input 
                type="text"
                value={localQuery}
                onChange={e => setLocalQuery(e.target.value)}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                style={{ 
                    flex: 1, 
                    padding: '1em 0 1em 1em',
                    border: 'none', 
                    outline: 'none', 
                    color: '#333',
                    borderRadius: '0.3125em 0 0 0.3125em',
                    fontFamily: 'IBM Plex Mono, monospace',
                    fontSize: '1rem',
                    height: '3.75em',
                    lineHeight:'27px'
                }}
                id="searchInput"
            />
            <label 
                htmlFor="searchInput" 
                style={{
                    position: 'absolute',
                    left: '0.9375em',
                    top: isFocused || localQuery ? '-0.750em' : '1em',
                    fontSize: isFocused || localQuery ? '0.8125em' : '1rem',
                    transition: '0.3s ease',
                    cursor: 'text',
                    color: '#aaa',
                    backgroundColor: 'white',
                    paddingLeft: '0.3125em',
                    paddingRight: '0.3125em',
                    clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 85% 100%, 80% 75%, 75% 100%, 0% 100%)'
                }}
            >
                search recipes
            </label>
            <button 
                type="submit"
                style={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    background: '#f5f5f5', 
                    border: 'none', 
                    cursor: 'pointer', 
                    borderRadius: '0 0.3125em 0.3125em 0',
                    padding: '0 calc(0.6em + 1vw)',
                }}
            >
                <SearchIcon style={{color:'black'}}/> 
            </button>
        </form>
    );
}

export default SearchBar;
