// App.js
import React, { useState, useEffect } from 'react';
import SearchRecipes from './Search/SearchRecipes';
import Header from './Header';
import { Container, CssBaseline, Grid, useTheme, useMediaQuery, CircularProgress, Box } from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import supabase from './supabaseClient';
import LoginPage from './Login';
import ListsPage from './Lists/ListsPage';
import BooksPage from './Books/BooksPage'; // Import the BooksPage component
import UploadRecipe from './UploadRecipe';
import RequireAuth from './RequireAuth';

function App() {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        // If in development mode, use a test user and persist it in localStorage
        // if (process.env.NODE_ENV === 'development') {
        //     const savedTestUser = JSON.parse(localStorage.getItem('testUser'));
    
        //     if (savedTestUser) {
        //         // If test user is already in localStorage, set it as the user
        //         setUser(savedTestUser);
        //     } else {
        //         // If no test user in localStorage, create one and save it
        //         const testUser = {
        //             id: '615f7df5-27d4-4d61-9666-cbedfc46e9e0',
        //             email: 'testuser@reciply.xyz',
        //             role: 'authenticated',
        //             user_metadata: {
        //                 avatar_url: 'https://example.com/avatar.png'
        //             }
        //         };
        //         setUser(testUser);
        //     }
        //     setLoading(false);
        //     return; // Skip the supabase auth check in development
        // }
    
        // Otherwise, proceed with real authentication (production mode)
        supabase.auth.getSession().then(({ data: { session } }) => {
            if (session?.user) {
                setUser(session.user);
            }
            setLoading(false); // Session check complete
        });
    
        const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
            setUser(session?.user || null);
            setLoading(false); // Session check complete
        });
    
        return () => subscription?.unsubscribe();
    }, []);
    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    const allowedEmails = ['tim.silber@me.com', 'testuser@reciply.xyz', 'testuser@example.com'];


    return (
        <Router>
            <Header user={user} />
            <Container component="main" maxWidth="xl">
                <CssBaseline />
                <Grid container spacing={3} direction="column" alignItems="center" justify="center">
                    <Grid item xs={12} style={{ width: isMobile ? "95%" : "80%" }}>
                        <Routes>
                            <Route path="/" element={<SearchRecipes user={user} />} />
                            <Route path="/login" element={<LoginPage user={user} />} />
                            
                            {/* Lists route */}
                            <Route path="/lists/*" element={
                                <RequireAuth user={user}>
                                    <ListsPage user={user} />
                                </RequireAuth>
                            } />
                            
                            {/* Books routes - books list and individual book details */}
                            <Route path="/books/*" element={
                                <RequireAuth user={user}>
                                    <BooksPage user={user} />
                                </RequireAuth>
                            } />

                            {/* Only your user account or the dev test user can access this route */}
                            <Route path="/upload" element={
                                <RequireAuth user={user} allowedEmails={allowedEmails}>
                                    <UploadRecipe user={user} />
                                </RequireAuth>
                            } />
                        </Routes>
                    </Grid>
                </Grid>
            </Container>
        </Router>
    );
}

export default App;
