import React from 'react';
import { Navigate } from 'react-router-dom';

const RequireAuth = ({ user, allowedEmails, children }) => {
    // Bypass authentication checks in development mode
    if (process.env.NODE_ENV === 'development') {
        return children;
    }

    if (!user) {
        // Redirect to login page if the user is not authenticated
        return <Navigate to="/login" />;
    }

    // Check if the user is in the allowedEmails list (if provided)
    if (allowedEmails && !allowedEmails.includes(user.email)) {
        // Redirect to home or another page if the user is not authorized
        return <Navigate to="/" />;
    }

    // Render the children if the user is authenticated and authorized
    return children;
};

export default RequireAuth;
