import React, { useState, useRef } from 'react';
import { AppBar, Toolbar, Typography, Button, Menu, MenuItem, IconButton, Avatar } from '@mui/material';
import { Link } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import supabase from './supabaseClient';

function Header({ user }) {
    const [menuOpen, setMenuOpen] = useState(false);
    const anchorRef = useRef(null);

    const handleSignOut = async () => {
        await supabase.auth.signOut();
        window.location.reload();
    };

    const handleMenuOpen = () => {
        setMenuOpen(true);
    };

    const handleMenuClose = () => {
        setMenuOpen(false);
    }; 

    const avatarUrl = user?.user_metadata?.avatar_url;

    // Function to handle logo click and reload the page
    const handleLogoClick = () => {
        window.location.href = '/';
    };

    const allowedEmails = ['tim.silber@me.com', 'testuser@reciply.xyz', 'testuser@example.com'];


    return (
        <div>
            <AppBar position="static" color="default" elevation={0} sx={{ backgroundColor: '#fff' }}>
                <Toolbar sx={{ padding: '20px 10px', display: 'flex', justifyContent: 'space-between', }}>
                    <Typography
                        variant="h4"
                        sx={{ fontFamily: 'Candal', fontWeight: 'bold', cursor: 'pointer' }}
                        onClick={handleLogoClick}
                    >
                        {'{reciply}'}
                    </Typography>
                    {user ? (
                        <div>
                            <IconButton
                                ref={anchorRef}
                                onClick={handleMenuOpen}
                                color="inherit"
                                edge="end"
                                sx={{
                                    borderRadius: '50%',
                                    padding: '.2em',
                                    marginRight: '.5em'
                                }}
                            >
                                {avatarUrl ? (
                                    <Avatar src={avatarUrl} sx={{ width: '1.5em', height: '1.5em' }} />
                                ) : (
                                    <AccountCircleIcon />
                                )}
                            </IconButton>
                            <Menu
                                anchorEl={anchorRef.current}
                                open={menuOpen}
                                onClose={handleMenuClose}
                                sx={{
                                    '& .MuiPaper-root': {
                                        backgroundColor: 'white',
                                        border: '1px solid #ccc',
                                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                    }
                                }}
                            >
                                <MenuItem disabled={true} sx={{ fontFamily: 'IBM Plex Mono', fontSize:'16px' }}>
                                    {user.email}
                                </MenuItem>
                                <Link to="/lists/favorites" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <MenuItem onClick={handleMenuClose} sx={{ fontFamily: 'IBM Plex Mono', fontSize:'16px' }}>
                                        favorites
                                    </MenuItem>
                                </Link>
                                <Link to="/lists" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <MenuItem onClick={handleMenuClose} sx={{ fontFamily: 'IBM Plex Mono', fontSize:'16px' }}>
                                        lists
                                    </MenuItem>
                                </Link>
                                <Link to="/books" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <MenuItem onClick={handleMenuClose} sx={{ fontFamily: 'IBM Plex Mono', fontSize:'16px' }}>
                                        books
                                    </MenuItem>
                                </Link>
                                {allowedEmails.includes(user.email) && (
                                    <Link to="/upload" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <MenuItem onClick={handleMenuClose} sx={{ fontFamily: 'IBM Plex Mono', fontSize:'16px' }}>
                                            upload
                                        </MenuItem>
                                    </Link>
                                )}
                                <MenuItem onClick={() => { handleSignOut(); handleMenuClose(); }} sx={{ fontFamily: 'IBM Plex Mono', fontSize:'16px' }}>
                                    sign out
                                </MenuItem>
                            </Menu>
                        </div>
                    ) : (
                        <Link to="/login" style={{ textDecoration: 'none', color: 'inherit' }}>
                            <Button color="inherit">Login</Button>
                        </Link>
                    )}
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default Header;
