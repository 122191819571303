import React, { useState, useEffect } from 'react';
import supabase from './supabaseClient';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';

function LoginPage({ session }) {
    
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        }

        window.addEventListener('resize', handleResize);
        
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    const containerStyles = {
        border: '1px solid #ccc',
        borderRadius: '.5em',
        width: isMobile ? '95vw' : '50vw',
        padding: isMobile ? '1em' : '1em 2em',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        transition: 'opacity 0.5s ease',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        maxHeight: '90vh',
        overflowY: 'auto'  // In case the content overflows the container's height.
    };

    const authContainerStyles = {
        width: '100%',  // Ensure that the container takes the full width of its parent.
    };

    return (
        session ? <div>You are already logged in!</div> : 
        <div style={containerStyles}>
            <div style={authContainerStyles}>
                <Auth 
                    supabaseClient={supabase} 
                    appearance={{
                        theme: ThemeSupa
                    }}
                    providers={['google', 'github']}
                    localization={{
                        variables: {
                            sign_in: {
                                email_label: 'Email',
                                password_label: 'Password',
                            },
                        },
                    }}
                />
            </div>
        </div>
    );
}

export default LoginPage;




// const recipesTheme = {
//     colors: {
//       brand: '#333', // Color taken from your input style
//       brandAccent: '#f5f5f5', // Background color from your button style
//       brandButtonText: '#333', // Assuming text color for the button
//       defaultButtonBackground: '#f5f5f5',
//       defaultButtonBackgroundHover: '#e5e5e5', // Lighter shade for hover effect
//       defaultButtonBorder: 'transparent',
//       defaultButtonText: '#333',
//       dividerBackground: '#eaeaea',
//       inputBackground: 'white', 
//       inputBorder: '#ccc', // From your form style
//       inputBorderHover: '#aaa', // Color taken from your label style
//       inputBorderFocus: '#aaa', // Color taken from your label style
//       inputText: '#333',
//       inputLabelText: '#aaa', // From your label style
//       inputPlaceholder: 'darkgray',
//     },
//     space: {
//       spaceSmall: '4px',
//       spaceMedium: '8px',
//       spaceLarge: '16px',
//       labelBottomMargin: '8px',
//       anchorBottomMargin: '4px',
//       emailInputSpacing: '4px',
//       socialAuthSpacing: '4px',
//       buttonPadding: '0 calc(0.6em + 1vw)', // From your button style
//       inputPadding: '1.25em 1em', // From your input style
//     },
//     fontSizes: {
//       baseBodySize: '1.125em', // From your input style
//       baseInputSize: '1.125em', // From your input style
//       baseLabelSize: '1.125em', // From your label style, though it changes based on state
//       baseButtonSize: '1em', // Assuming size for button text
//     },
//     fonts: {
//       bodyFontFamily: 'Montserrat', // From your form style
//       buttonFontFamily: 'Montserrat', // Assuming same as form
//       inputFontFamily: 'Montserrat', // Assuming same as form
//       labelFontFamily: 'Montserrat', // Assuming same as form
//     },
//     borderWidths: {
//       buttonBorderWidth: '1px',
//       inputBorderWidth: '0.0625em', // From your form style
//     },
//     radii: {
//       borderRadiusButton: '0.3125em', // From your form and input styles
//       buttonBorderRadius: '0.3125em', // From your form and input styles
//       inputBorderRadius: '0.3125em', // From your form and input styles
//     },
//   }
  