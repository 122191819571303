// BooksPage.js
import { Routes, Route } from 'react-router-dom';
import BookDetail from './BookDetail';
import React, { useState, useEffect } from 'react';
import { Typography, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import supabase from '../supabaseClient'; // Assuming you have the client setup


function Books() {
    const [books, setBooks] = useState([]);

    // Fetch books from Supabase
    useEffect(() => {
        const fetchBooks = async () => {
            const { data, error } = await supabase
                .from('books')
                .select('id, name, author');
            
            if (error) {
                console.error('Error fetching books:', error);
            } else {
                setBooks(data || []);
            }
        };

        fetchBooks();
    }, []);

    const cardStyle = {
        border: '1px solid rgb(204, 204, 204)',
        padding: '1em',
        margin: '1em 0',
        borderRadius: '.5em',
        cursor: 'pointer',
        transition: 'box-shadow 0.3s',
        textDecoration: 'none',
    };

    const cardTitleStyle = {
        fontSize: '1.2em',
        marginBottom: '5px',
        color: 'black',
    };

    return (
        <Grid>
            <div style={{ marginTop: '1em' }}>
                <Typography variant="h4" sx={{ fontSize: '1.3rem', fontFamily: 'IBM Plex Mono', cursor: 'pointer' }}>
                    books
                </Typography>
            </div>
            <div>
                {books.map((book) => (
                    <Link key={book.id} to={`/books/${encodeURIComponent(book.name)}`} style={{ textDecoration: 'none' }}>
                        <div style={cardStyle}>
                            <div style={cardTitleStyle}>
                                <Typography sx={{fontFamily: 'IBM Plex Mono'}}>
                                    {book.name}
                                </Typography>
                                <Typography sx={{ fontSize: '.8rem', color: 'gray', fontFamily: 'IBM Plex Mono' }}>
                                    <em>{book.author ? `by ${book.author}` : 'various authors'}</em>
                                </Typography>
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </Grid>
    );
}

function BooksPage({ user }) {
    return (
        <Routes>
            {/* Route for the list of books */}
            <Route path="/" element={<Books user={user} />} />

            {/* Dynamic route for individual book details */}
            <Route path="/:bookName" element={<BookDetail user={user} />} />
        </Routes>
    );
}

export default BooksPage;
