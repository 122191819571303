import * as React from "react";
import {
  useState,
  useEffect,
  useRef,
  useCallback,
  useLayoutEffect,
} from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import AddIcon from "@mui/icons-material/Add";
import supabase from "../supabaseClient";
import {
  IconButton,
  Skeleton,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  TextField,
  Snackbar,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import AddCircle from "@mui/icons-material/AddCircle";
import { useSwipeable } from "react-swipeable"; // Import useSwipeable
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import ShareIcon from "@mui/icons-material/Share";
import GroceryIcon from "./assets/grocery.svg";
import SprintIcon from "./assets/sprint.svg";
import AlarmWaitIcon from "./assets/alarm_wait.svg";

const ANIMATED_RECIPES_COUNT = 10;
const DESIRED_SPEED = 200; // em/s

function Recipe({
  recipe,
  index,
  isFavorited,
  handleFavorite,
  user,
  handleAddToList,
}) {
  const contentRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [transitionDuration, setTransitionDuration] = useState("0.3s");
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const [showAllIngredients, setShowAllIngredients] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const updateContentHeight = useCallback(() => {
    if (contentRef.current) {
      const heightInPx = contentRef.current.scrollHeight;
      setContentHeight(heightInPx);
      const heightInEm = heightInPx / 16; // Convert px to em
      const durationInSeconds = heightInEm / DESIRED_SPEED;
      setTransitionDuration(`${durationInSeconds}s`);
    }
  }, []);

  // Use ResizeObserver to update content height when content size changes
  useEffect(() => {
    if (contentRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        updateContentHeight();
      });
      resizeObserver.observe(contentRef.current);
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [contentRef, updateContentHeight]);

  // Use useLayoutEffect to ensure synchronous updates
  useLayoutEffect(() => {
    updateContentHeight();
  }, [recipe, isOpen, showAllIngredients, updateContentHeight]);

  // Attach resize listener once on mount and cleanup on unmount
  useEffect(() => {
    window.addEventListener("resize", updateContentHeight);
    return () => {
      window.removeEventListener("resize", updateContentHeight);
    };
  }, [updateContentHeight]);

  // Swipe handlers
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      // if (!isOpen) {
        setShowActions(true);
      // }
    },
    onSwipedRight: () => {
      // if (!isOpen) {
        setShowActions(false);
      // }
    },
    preventScrollOnSwipe: true,
    trackTouch: true,
    trackMouse: false,
    delta: 10,
  });

  const handleTap = (e) => {
    if (e.target.closest(".quick-action-button")) {
      return;
    }
    if (!showActions) {
      setIsOpen(!isOpen);
    } else {
      setShowActions(false);
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "ingredient",
      flex: 1,
      renderCell: (params) => (
        <div
          style={{
            whiteSpace: "normal",
            wordWrap: "break-word",
            padding: "8px",
          }}
        >
          <div>{params.row.name}</div>
          {params.row.preparation && (
            <div
              style={{
                fontStyle: "italic",
                color: "gray",
                marginTop: "0.25em",
              }}
            >
              {params.row.preparation}
            </div>
          )}
        </div>
      ),
    },
    {
      field: "quantity",
      headerName: "quantity",
      flex: 0.5,
      minWidth: 145, // Ensures the header "Quantity" is not cut off
      renderCell: (params) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            padding: "8px",
          }}
        >
          {params.value}
        </div>
      ),
    },
  ];

  // Helper function to sanitize values
  function sanitize(value) {
    if (value === null || value === undefined) {
      return "";
    }
    if (typeof value === "string" && value.toLowerCase() === "null") {
      return "";
    }
    return value;
  }

  const rows = recipe.ingredients.map((ingredient, idx) => ({
    id: idx,
    name: sanitize(ingredient.name).toLowerCase(),
    preparation: sanitize(ingredient.preparation).toLowerCase(),
    quantity: `${sanitize(ingredient.quantity?.value)} ${sanitize(
      ingredient.quantity?.unit
    )}`.trim(),
  }));

  const hasMoreThanSixIngredients = rows.length > 6;

  // Populate content with animation
  useEffect(() => {
    const baseDelay = 200;
    const staggerDelay = 150;

    const timer = setTimeout(
      () => {
        setIsVisible(true);
      },
      index < ANIMATED_RECIPES_COUNT
        ? baseDelay + staggerDelay * index
        : baseDelay + staggerDelay * ANIMATED_RECIPES_COUNT
    );

    return () => clearTimeout(timer);
  }, [index]);

  const handleViewOriginalRecipe = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const encodedBookName = encodeURIComponent(recipe.book_name);

    const pathParts = recipe.path.split("/");
    const limitedPath = pathParts.slice(-2).join("/");
    const encodedPath = encodeURIComponent(limitedPath);

    const encodedRecipeTitle = encodeURIComponent(recipe.title);

    const finalURL = `/books/${encodedBookName}?spineLocation=${encodedPath}&query=${encodedRecipeTitle}`;

    window.open(finalURL, "_blank", "noopener,noreferrer");
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  function formatTime(minutes) {
    if (minutes <= 59) {
      return `${minutes}m`;
    } else {
      const hours = Math.floor(minutes / 60);
      const mins = minutes % 60;
      if (mins === 0) {
        return `${hours}h`;
      } else {
        return `${hours}h${mins}m`;
      }
    }
  }

  return (
    <div
      key={recipe.id}
      {...handlers}
      style={{
        border: "1px solid #ccc",
        borderRadius: ".5em",
        marginBottom: "1em",
        opacity: isVisible ? 1 : 0,
        transition: "opacity 0.5s ease",
        cursor: "pointer",
        position: "relative",
        overflow: "hidden",
        touchAction: "pan-y",
      }}
      onClick={handleTap}
    >
      {/* Content Wrapper */}
      <div
        className="recipe-content"
        style={{
          display: "flex",
          flexDirection: "column",
          transform: showActions ? "translateX(-168px)" : "translateX(0)",
          transition: "transform 0.3s ease",
        }}
      >
        <div
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between",
            // alignItems: "flex-start",
            padding: ".75em .75em 0 .75em",
          }}
        >
          {/* Left Column */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1, // Allows the left column to take available space,
            }}
          >
            <Typography
              variant="h6"
              style={{
                margin: 0,
                fontSize: 18,
                fontFamily: "IBM Plex Mono",
                fontWeight: 400,
                lineHeight: "27px",
                wordWrap: "break-word",
                ...(isOpen
                  ? {}
                  : {
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }),
              }}
            >
              {recipe.title.toLowerCase()}
            </Typography>

            <Typography
              variant="body2"
              style={{
                margin: 0,
                color: "#808080",
                fontSize: 12,
                fontFamily: "IBM Plex Mono",
                fontStyle: "italic",
                fontWeight: 400,
                lineHeight: "20px",
                ...(isOpen
                  ? {}
                  : {
                      display: "-webkit-box",
                      WebkitLineClamp: 1,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }),
              }}
            >
              <em>{recipe.book_name}</em>
            </Typography>

            {/* Stats bar */}
            <div
              style={{
                width: "100%",
                paddingTop: 4,
                paddingBottom: '.75em',
                display: "flex",
                alignItems: "center",
              }}
            >
              {/* Stats Wrapper */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10, // Consistent spacing between stat items
                  fontFamily: "IBM Plex Mono",
                  marginTop: "5px",
                }}
              >
                {/* First stat: Ingredients */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 3,
                  }}
                >
                  {/* Icon */}
                  <div style={{ width: 18, height: 18, flexShrink: 0 }}>
                    <img
                      src={GroceryIcon}
                      alt="Ingredients Icon"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  {/* Text */}
                  <div
                    style={{
                      color: "#808080",
                      fontSize: 12,
                      fontWeight: 400,
                      lineHeight: "20px",
                      whiteSpace: "nowrap",
                      flexShrink: 0,
                    }}
                  >
                    {recipe.ingredients.length}
                  </div>
                </div>

                {/* Second stat: Active Time */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 3,
                  }}
                >
                  {/* Icon */}
                  <div style={{ width: 20, height: 20, flexShrink: 0 }}>
                    <img
                      src={SprintIcon}
                      alt="Active Time Icon"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  {/* Text */}
                  <div
                    style={{
                      color: "#808080",
                      fontSize: 12,
                      fontWeight: 400,
                      lineHeight: "20px",
                      whiteSpace: "nowrap",
                      flexShrink: 0,
                    }}
                  >
                    {formatTime(recipe.time)}
                  </div>
                </div>

                {/* Third stat: Inactive Time */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 3,
                  }}
                >
                  {/* Icon */}
                  <div style={{ width: 20, height: 20, flexShrink: 0 }}>
                    <img
                      src={AlarmWaitIcon}
                      alt="Inactive Time Icon"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  {/* Text */}
                  <div
                    style={{
                      color: "#808080",
                      fontSize: 12,
                      fontWeight: 400,
                      lineHeight: "20px",
                      whiteSpace: "nowrap",
                      flexShrink: 0,
                    }}
                  >
                    {formatTime(recipe.inactive_time)}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div style={{ display: "flex", paddingTop: '2px' }}>
            {!isOpen && recipe.image_url && (
              <Box
                style={{
                  width: "75px",
                  height: "75px",
                  borderRadius: ".5em",
                  border: "1px solid #ccc",
                  overflow: "hidden",
                  flexShrink: 0,
                  marginLeft: "1em", // Add space between columns
                  
                }}
              >
                <img
                  alt={`${recipe.title}`}
                  src={recipe.image_url}
                  onLoad={updateContentHeight}
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                    transform: "scale(1.1)",
                  }}
                />
              </Box>
            )}
            {!isMobile && (
              <ExpandMoreIcon
                style={{
                  transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
                  transition: "transform 0.3s",
                  alignSelf: "center",
                  margin: "0 0 10px 0.5em", // Space between image and icon
                }}
              />
            )}
          </div>
        </div>

        {/* Expanded Content */}
        <div
          ref={contentRef}
          style={{
            overflow: "hidden",
            transition: `max-height ${transitionDuration} ease`,
            maxHeight: isOpen
              ? showAllIngredients
                ? "none"
                : `${contentHeight}px`
              : "0px",
            // marginTop: "0.5em",
            padding: "0 0.75em",
          }}
        >
          {isOpen && (
            <>
              {recipe.image_url && (
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    style={{
                      maxWidth: "400px",
                      height: "400px",
                      borderRadius: ".5em",
                      border: "1px solid #ccc",
                      overflow: "hidden",
                      marginBottom: "1em",
                      alignItems: "center",
                      justifyContent: "center",
                      alignSelf: "center",
                      display: "flex",
                    }}
                  >
                    <img
                      alt={`${recipe.title}`}
                      src={recipe.image_url}
                      onLoad={updateContentHeight} // Update height when image loads
                      style={{
                        objectFit: "cover",
                        height: "100%",
                        scale: "1.1",
                      }}
                    />
                  </Box>
                </Box>
              )}
              <Typography
                variant="body1"
                style={{
                  marginBottom: "1em",
                  fontFamily: "IBM Plex Mono",
                  fontSize: "14px",
                }}
              >
                {recipe.description ? recipe.description : ""}
              </Typography>
              <Box
                onClick={(e) => e.stopPropagation()}
                sx={{
                  maxWidth: "900px", // Set a maximum width for the DataGrid
                  margin: "0 auto", // Center the DataGrid
                  position: "relative",
                }}
              >
                <DataGrid
                  rows={
                    showAllIngredients || !hasMoreThanSixIngredients
                      ? rows
                      : rows.slice(0, 6)
                  }
                  columns={columns}
                  disableRowSelectionOnClick
                  onClick={(e) => e.stopPropagation()}
                  getRowHeight={() => "auto"} // Set the row height to auto
                  autoHeight
                  pageSizeOptions={[5, 10, 25, 100]} // Options for page size
                  pagination={showAllIngredients} // Only show pagination if showing all ingredients
                  paginationModel={paginationModel}
                  onPaginationModelChange={(newModel) =>
                    setPaginationModel(newModel)
                  }
                  sx={{
                    borderRadius: ".5em",
                    fontFamily: "IBM Plex Mono",
                    fontSize: "12px",
                    "& .MuiDataGrid-columnHeader": {
                      color: "#333", // Darker text color for contrast
                      borderBottom: "2px solid #ddd", // A subtle border to distinguish the header
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                      color: "#333", // Darker text color for contrast
                      fontWeight: "600", // Slightly bold text for the header
                      fontSize: "14px", // A modest font size increase
                      padding: "10px", // Ensure enough padding for readability
                    },
                    "& .MuiDataGrid-columnHeaderDraggableContainer": {
                      width: "fit-content",
                    },
                    "& .MuiDataGrid-row:nth-of-type(odd)": {
                      backgroundColor: "#f0f0f0", // Light background for odd rows
                    },
                    "& .MuiDataGrid-row:nth-of-type(even)": {
                      backgroundColor: "#ffffff", // White background for even rows for minimal contrast
                    },
                    "& .MuiDataGrid-row:hover": {
                      backgroundColor: "#e9ecef", // A soft hover effect that fits the minimalist theme
                    },
                    "& .MuiDataGrid-cell": {
                      display: "block", // Ensure each cell is block level
                      lineHeight: "1.5em", // Maintain readability with proper line height
                      padding: "10px", // Maintain padding for each cell
                      boxSizing: "border-box", // Ensure padding doesn't affect cell size calculation
                    },
                  }}
                />
                {hasMoreThanSixIngredients && !showAllIngredients && (
                  <div style={{ position: "relative" }}>
                    {/* Blur and Gradient Background Element */}

                    {/* Show More Drawer */}
                    <div
                      onClick={() => setShowAllIngredients(true)}
                      style={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        height: "55px", // Reduced height for the drawer
                        background: "#f0f0f0", // Gray gradient
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#333", // Darker text color for contrast
                        fontSize: "14px",
                        fontFamily: "IBM Plex Mono",
                        cursor: "pointer",
                        fontWeight: "600",
                        // border: '1px solid #ccc',
                        border: "1px solid rgba(224, 224, 224, 1)",
                        borderBottomLeftRadius: ".5em",
                        borderBottomRightRadius: ".5em",
                        zIndex: 2, // Ensure this sits on top
                      }}
                    >
                      + show all ingredients
                    </div>
                  </div>
                )}
              </Box>

              <Box>
                <Typography
                  variant="subtitle1"
                  style={{
                    marginTop: "1em",
                    marginBottom: "0.5em",
                    fontFamily: "IBM Plex Mono",
                    fontSize: "14px",
                  }}
                >
                  Instructions:
                </Typography>
                <ol
                  style={{
                    // paddingLeft: "1.5em",
                    lineHeight: "1.6em",
                    margin: "0 0 1em 0",
                    fontFamily: "IBM Plex Mono",
                    fontSize: "14px",
                  }}
                >
                  {recipe.instructions.map((instruction, idx) => (
                    <li key={idx} style={{ marginBottom: "0.5em" }}>
                      {instruction}
                    </li>
                  ))}
                </ol>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "1em 0",
                  borderTop: "1px solid #ccc",
                  marginTop: "2em",
                }}
              >
                <button
                  onClick={handleViewOriginalRecipe}
                  style={{
                    padding: "0.75em 1.5em",
                    border: "1px solid #ccc",
                    borderRadius: ".25em",
                    color: "#333",
                    backgroundColor: "#f9f9f9",
                    textAlign: "center",
                    textDecoration: "none",
                    fontSize: "0.875rem",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    fontFamily: "IBM Plex Mono",
                  }}
                  onMouseEnter={(e) =>
                    (e.target.style.backgroundColor = "#e0e0e0")
                  }
                  onMouseLeave={(e) =>
                    (e.target.style.backgroundColor = "#f9f9f9")
                  }
                >
                  view original recipe
                </button>
              </Box>
            </>
          )}
        </div>
      </div>

      {/* Quick Actions */}
      {showActions && (
        <div
          className="quick-actions"
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            height: "100%",
            width: "168px",
            backgroundColor: "#f0f0f0",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            zIndex: 1,
          }}
        >
          <IconButton
            className="quick-action-button"
            onClick={(e) => {
              e.stopPropagation();
              if (!user) {
                setSnackbarMessage("Please login to favorite recipes");
                setSnackbarOpen(true);
                return;
              }
              handleFavorite(e, recipe.id);
            }}
            sx={!user ? { color: "action.disabled", opacity: 0.5 } : {}}
          >
            {isFavorited ? (
              <FavoriteIcon color="error" />
            ) : (
              <FavoriteBorderIcon />
            )}
          </IconButton>

          <IconButton
            className="quick-action-button"
            onClick={(e) => {
              e.stopPropagation();
              if (!user) {
                setSnackbarMessage("Please login to add recipes to your list");
                setSnackbarOpen(true);
                return;
              }
              handleAddToList(e, recipe.id);
            }}
            sx={!user ? { color: "action.disabled", opacity: 0.5 } : {}}
          >
            <PlaylistAddIcon />
          </IconButton>

          <IconButton
            className="quick-action-button"
            onClick={(e) => {
              e.stopPropagation();
              // Implement your share logic here
            }}
          >
            <ShareIcon />
          </IconButton>
        </div>
      )}

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="info"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

function RecipeList({
  loading,
  errorMessage,
  recipes,
  hasSearched,
  user,
  showResultsText,
  totalCount,
}) {
  const [favoritedRecipes, setFavoritedRecipes] = useState([]);
  const [defaultListId, setDefaultListId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRecipeId, setSelectedRecipeId] = useState(null);
  const [userLists, setUserLists] = useState([]);
  const [selectedListIds, setSelectedListIds] = useState([]);
  const [newListName, setNewListName] = useState("");
  const [recipeInLists, setRecipeInLists] = useState([]);
  const [showCreateList, setShowCreateList] = useState(false);

  const handleCreateList = async () => {
    if (newListName.trim() === "") return;

    const { data, error } = await supabase
      .from("saved_lists")
      .insert([{ list_name: newListName, user_id: user.id }])
      .single()
      .select();

    if (error) {
      console.error("Error creating new list:", error);
    } else {
      setNewListName("");
      setUserLists((prev) => [...prev, data]);
    }
  };

  useEffect(() => {
    if (user) {
      const fetchUserLists = async () => {
        const { data, error } = await supabase
          .from("saved_lists")
          .select("*")
          .eq("user_id", user.id);

        if (!error) {
          setUserLists(data);
        }
      };

      fetchUserLists();
    }
  }, [user]);

  const handleAddToList = async (e) => {
    e.stopPropagation();

    if (selectedListIds.length === 0 || !selectedRecipeId) return;

    const insertData = selectedListIds.map((listId) => ({
      list_id: listId,
      recipe_id: selectedRecipeId,
    }));

    try {
      const { error } = await supabase.from("lists_recipes").insert(insertData);

      if (error) {
        throw error;
      }
    } catch (error) {
      console.error("Error adding recipe to lists:", error);
    }

    // Reset states and close the dialog
    setSelectedListIds([]);
    setSelectedRecipeId(null);
    setOpenDialog(false);
  };

  const handleRemoveFromList = async (e, listId) => {
    e.stopPropagation();

    try {
      const { data, error } = await supabase
        .from("lists_recipes")
        .delete()
        .eq("list_id", listId)
        .eq("recipe_id", selectedRecipeId);

      if (error) {
        throw error;
      }

      console.log("Recipe removed successfully:", data);

      // Update recipeInLists to remove the listId
      setRecipeInLists((prev) => prev.filter((id) => id !== listId));
    } catch (error) {
      console.error("Error removing recipe from list:", error);
    }
  };

  const openAddToListDialog = (e, recipeId) => {
    e.stopPropagation();

    // Fetch the lists this recipe is in and update recipeInLists
    const fetchListsForRecipe = async () => {
      const { data, error } = await supabase
        .from("lists_recipes")
        .select("list_id")
        .eq("recipe_id", recipeId);

      if (!error) {
        setRecipeInLists(data.map((item) => item.list_id));
      }
    };

    fetchListsForRecipe();

    setSelectedRecipeId(recipeId);
    setOpenDialog(true);
  };

  useEffect(() => {
    if (user) {
      const fetchDefaultListId = async () => {
        const { data: listData, error: listError } = await supabase
          .from("saved_lists")
          .select("list_id")
          .eq("user_id", user.id)
          .eq("list_name", "favorites");

        if (!listError && listData && listData.length > 0) {
          setDefaultListId(listData[0].list_id);
        }
      };

      fetchDefaultListId();
    }
  }, [user]);

  useEffect(() => {
    if (user && defaultListId) {
      const fetchFavorites = async () => {
        const { data, error } = await supabase
          .from("lists_recipes")
          .select("recipe_id")
          .eq("list_id", defaultListId);

        if (!error) {
          setFavoritedRecipes(data.map((d) => d.recipe_id));
        }
      };

      fetchFavorites();
    }
  }, [user, defaultListId]);

  const handleFavorite = async (event, recipeId) => {
    event.stopPropagation();

    const currentFavoritedStatus = favoritedRecipes.includes(recipeId);
    setFavoritedRecipes((prevState) =>
      currentFavoritedStatus
        ? prevState.filter((id) => id !== recipeId)
        : [...prevState, recipeId]
    );

    try {
      await supabase.rpc("toggle_recipe_in_default_list", {
        recipe_id_param: recipeId,
        user_id_param: user.id,
      });
    } catch (e) {
      console.error("Error:", e);
      setFavoritedRecipes((prevState) =>
        currentFavoritedStatus
          ? [...prevState, recipeId]
          : prevState.filter((id) => id !== recipeId)
      );
    }
  };

  if (errorMessage) return <Alert severity="error">{errorMessage}</Alert>;
  if (loading) {
    return (
      <div>
        <div
          style={{
            marginBottom: "1em",
            fontFamily: "Montserrat",
            color: "rgb(170, 170, 170)",
            fontSize: ".8rem",
            marginLeft: "auto",
            textAlign: "right",
            transition: "opacity 0.5s ease",
          }}
        >
          <Skeleton
            variant="text"
            width={100}
            style={{ display: "inline-block" }}
          />
        </div>

        {Array.from({ length: 10 }).map((_, index) => (
          <div
            key={index}
            style={{
              border: "1px solid #ccc",
              borderRadius: ".5em",
              padding: "1em",
              marginBottom: "1em",
              transition: "opacity 0.5s ease",
            }}
          >
            <Skeleton variant="rectangular" height={40} />
            <Skeleton variant="text" />
          </div>
        ))}
      </div>
    );
  }

  // Show alert if no recipes are found after search
  if (hasSearched && recipes.length === 0) {
    return (
      <Alert severity="error" style={{ marginTop: "1em" }}>
        No recipes found. Please try different filters or search terms.
      </Alert>
    );
  }

  if (!loading && recipes.length > 0) {
    return (
      <>
        {showResultsText && (
          <div
            style={{
              marginBottom: "1em",
              fontFamily: "IBM Plex Mono, monospace",
              color: "rgb(170, 170, 170)",
              fontSize: "13px",
              fontWeight: "400",
              lineHeight: '23px',
              marginLeft: "auto",
              textAlign: "right",
              transition: "opacity 0.5s ease",
            }}
          >
            {totalCount} recipes found
          </div>
        )}
        {recipes.map((recipe, index) => (
          <Recipe
            key={recipe.id}
            recipe={recipe}
            index={index}
            isFavorited={favoritedRecipes.includes(recipe.id)}
            handleFavorite={(e) => handleFavorite(e, recipe.id)}
            user={user}
            handleAddToList={(e) => openAddToListDialog(e, recipe.id)} // Here you pass it down
          />
        ))}
        <Dialog
          open={openDialog}
          onClose={() => {
            setOpenDialog(false);
            setSelectedListIds([]);
            setShowCreateList(false);
          }}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle sx={{ paddingBottom: 0 }}>
            Add recipe to list
          </DialogTitle>
          <DialogContent sx={{ paddingBottom: ".5em" }}>
            <List sx={{ paddingTop: "0!important" }} component="nav">
              {userLists
                .sort(
                  (a, b) =>
                    (recipeInLists.includes(a.list_id) ? 1 : -1) -
                    (recipeInLists.includes(b.list_id) ? 1 : -1)
                )
                .map((list) => (
                  <ListItem
                    button
                    key={list.list_id}
                    onClick={() => {
                      if (recipeInLists.includes(list.list_id)) return;

                      if (selectedListIds.includes(list.list_id)) {
                        setSelectedListIds((prev) =>
                          prev.filter((id) => id !== list.list_id)
                        );
                      } else {
                        setSelectedListIds((prev) => [...prev, list.list_id]);
                      }
                    }}
                    style={{
                      background: recipeInLists.includes(list.list_id)
                        ? "#dddddd44"
                        : "",
                      display: "flex",
                      justifyContent: "space-between", // Ensures space between text and button
                      alignItems: "center", // Vertically center the content
                    }}
                  >
                    <div
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "calc(100% - 150px)", // Adjust based on the expected width of the button and any margins/paddings
                        opacity: recipeInLists.includes(list.list_id) ? 0.8 : 1,
                        pointerEvents: recipeInLists.includes(list.list_id)
                          ? "none"
                          : "auto",
                        flex: 1, // Take up available space
                      }}
                    >
                      {list.list_name}
                    </div>

                    {selectedListIds.includes(list.list_id) && (
                      <ListItemIcon>
                        <CheckIcon />
                      </ListItemIcon>
                    )}
                    {recipeInLists.includes(list.list_id) && (
                      <Button
                        color="secondary"
                        size="small"
                        style={{
                          opacity: 1,
                          color: "#999",
                          marginLeft: "8px", // Add some spacing between the text and the button
                          fontSize: ".8rem",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveFromList(e, list.list_id);
                        }}
                      >
                        Remove from list
                      </Button>
                    )}
                  </ListItem>
                ))}
            </List>
            {showCreateList ? (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: ".5em",
                    marginTop: ".5em",
                  }}
                >
                  <TextField
                    value={newListName}
                    onChange={(e) => setNewListName(e.target.value)}
                    placeholder="New list name"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      style: {
                        height: "3em",
                      },
                    }}
                  />

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCreateList}
                    sx={{
                      backgroundColor: "white",
                      boxShadow: "none",
                      border: "1px solid #ccc",
                    }}
                  >
                    <AddIcon style={{ color: "#aaa" }} />
                  </Button>
                </div>
              </>
            ) : (
              <div
                onClick={() => setShowCreateList(true)}
                style={{ display: "flex", alignItems: "center" }}
              >
                <IconButton>
                  <AddCircle color="primary" />
                </IconButton>
                <Typography
                  variant="body1"
                  color="primary"
                  sx={{ padding: ".2em", cursor: "pointer" }}
                >
                  Create list
                </Typography>
              </div>
            )}
          </DialogContent>

          <DialogActions sx={{ marginBottom: ".5em" }}>
            <Button onClick={() => setOpenDialog(false)} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleAddToList}
              color="primary"
              variant="contained"
            >
              Add recipe
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default RecipeList;
