import React from 'react';
import { Button, Typography } from '@mui/material';
import { Checkbox } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import AddIcon from '@mui/icons-material/Add';

export function CustomMarkdown({ content, setMarkdownContent, disabled }) {
    const lines = content.split('\n').map((line, index) => {
        let trimmedLine = line.trim();

        // Convert single bracket format to double bracket format
        if (trimmedLine.includes('- [ ]')) trimmedLine = trimmedLine.replace('- [ ]', '- [[ ]]');
        if (trimmedLine.includes('* [ ]')) trimmedLine = trimmedLine.replace('* [ ]', '* [[ ]]');
        if (trimmedLine.includes('- [x]')) trimmedLine = trimmedLine.replace('- [x]', '- [[ x ]]');
        if (trimmedLine.includes('* [x]')) trimmedLine = trimmedLine.replace('* [x]', '* [[ x ]]');

        const isChecked = trimmedLine.includes('[[ x ]]');

        if (trimmedLine.startsWith('- [[ ]]') ||
            trimmedLine.startsWith('* [[ ]]') ||
            trimmedLine.startsWith('- [[ x ]]') ||
            trimmedLine.startsWith('* [[ x ]]')) {
            return (
                <div key={index} style={{ display: 'flex', alignItems: 'center', margin: '.5em' }}>
                    <Checkbox
                        color="primary"
                        checked={isChecked}
                        // onChange={() => handleCheckboxChange(index, trimmedLine)}
                        disabled={disabled}
                        sx={{ padding: '0 .5em .5em .5em', alignSelf: 'flex-start' }} />
                    <div style={{ alignSelf: 'flex-start', textTransform: 'lowercase', fontFamily: 'IBM Plex Mono', fontSize:'14px' }}>
                        {trimmedLine
                            .replace('- [[ x ]]', '')
                            .replace('* [[ x ]]', '')
                            .replace('- [[ ]]', '')
                            .replace('* [[ ]]', '')
                            .trim()}
                    </div>
                </div>
            );
        }

        return <ReactMarkdown key={index} remarkPlugins={[remarkGfm]}>{trimmedLine}</ReactMarkdown>;
    });

    return <div>                   <div style={{}}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant='overline' sx={{ fontFamily: 'Montserrat', textTransform: 'lowercase', fontSize: '1.2rem', letterSpacing: 0 }}>shopping list</Typography>
            <Button disabled>
                <AddIcon  /> Add item
            </Button>
        </div>
    </div>{lines}</div>;
}
