import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import supabase from "../supabaseClient";
import { Chip, Button, Grid } from "@mui/material";
import RecipeList from "../Recipes/RecipeList";
import SearchBar from "./SearchBar";
import { useNavigate, useLocation } from "react-router-dom";
import DynamicFilter from "./DynamicFilter";
import Pagination from "@mui/material/Pagination";

function SearchRecipes({ user }) {
  const [recipes, setRecipes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showRecipes, setShowRecipes] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [hasSearched, setHasSearched] = useState(false);
  const ITEMS_PER_PAGE = 10;

  const navigate = useNavigate();
  const location = useLocation();
  const recipeListRef = useRef(null);

  // Extract values directly from URL
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get("query") || "";
  const books = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get("books")
      ? decodeURIComponent(queryParams.get("books")).split(";;")
      : [];
  }, [location.search]);

  const authors = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get("authors")
      ? decodeURIComponent(queryParams.get("authors")).split(";;")
      : [];
  }, [location.search]);
  const currentPage = Number(queryParams.get("page") || "1");

  const timeLimit = queryParams.get("time") || ""; // New time limit query parameter

  const filters = { books, authors, time: timeLimit };
  const selectedFilters = [
    ...books.map((book) => ({ type: "Book", value: book })),
    ...authors.map((author) => ({ type: "Author", value: author })),
    ...(timeLimit ? [{ type: "Time", value: timeLimit }] : []), // Include time filter if present
  ];

  const updateURL = useCallback(
    (currentQuery, currentFilters, currentPage) => {
      const queryParams = new URLSearchParams();

      if (currentQuery) {
        queryParams.append("query", currentQuery);
      }
      if (currentFilters.books.length) {
        queryParams.append("books", currentFilters.books.join(";;"));
      }
      if (currentFilters.authors.length) {
        queryParams.append("authors", currentFilters.authors.join(";;"));
      }
      if (currentFilters.time) {
        queryParams.append("time", currentFilters.time); // Append time filter
      }
      if (currentPage > 1) {
        queryParams.append("page", currentPage.toString());
      }
      navigate(`?${queryParams.toString()}`);
    },
    [navigate]
  );

  const parseSearchQuery = (query) => {
    const regex = /([^\s"]+)|"([^"]+)"/g;
    const terms = [];
    let match;

    while ((match = regex.exec(query)) !== null) {
      terms.push(match[2] ? `"${match[2]}"` : match[1]);
    }
    return terms;
  };

  useEffect(() => {
    const searchTerms = parseSearchQuery(query);

    const handleSearch = async () => {
      if (!query && books.length === 0 && authors.length === 0 && !timeLimit) {
        return;
      }

      // Check if it's the first page
      if (currentPage === 1) {
        setLoading(true);
        setHasSearched(true);
        // Add a delay only for the first page
        setTimeout(() => {
          fetchData();
        }, 500);
      } else {
        fetchData();
      }
    };

    const fetchData = async () => {
      const { data, error } = await supabase.rpc("search_recipes_ranked", {
        ingredient_names: searchTerms,
        book_name_filters: books,
        book_author_filters: authors,
        time_limit: timeLimit ? parseInt(timeLimit) : null, // Pass time limit as an integer
        page_limit: ITEMS_PER_PAGE,
        page_offset: (currentPage - 1) * ITEMS_PER_PAGE,
      });

      const totalCount = data && data.length > 0 ? data[0].total_count : 0;
      setTotalCount(totalCount);
      setRecipes(data);
      setLoading(false);
      setShowRecipes(true);
      setHasSearched(true);

      if (error) {
        console.error("Error fetching recipes:", error);
        setErrorMessage(error.message);
        return;
      }
    };

    handleSearch();
  }, [query, books, authors, currentPage, timeLimit]);

  const handlePageChange = (event, value) => {
    updateURL(query, filters, value);
    recipeListRef.current.scrollIntoView();
  };

  const updateFilters = (type, value, isDelete = false) => {
    const updatedFilters = { ...filters };

    // Explicitly handle books, authors, and time
    if (type === "Book") {
      updatedFilters.books = isDelete
        ? filters.books.filter((book) => book !== value) // Remove from books array
        : [...filters.books, value]; // Add to books array
    } else if (type === "Author") {
      updatedFilters.authors = isDelete
        ? filters.authors.filter((author) => author !== value) // Remove from authors array
        : [...filters.authors, value]; // Add to authors array
    } else if (type === "Time") {
      updatedFilters.time = value; // Set or clear the time filter directly
    }

    updateURL(query, updatedFilters, 1); // Reset to the first page after filter change
  };

  const containerStyle = {
    transition: "margin-top 0.5s ease-in-out",
    marginTop: hasSearched ? "2.5em" : "calc(35vh - 1.25em)",
    marginBottom: "2.5em",
  };

  return (
    <Grid container spacing={3} direction="row" style={containerStyle}>
      <Grid item xs={12} ref={recipeListRef}>
        <SearchBar
          query={query}
          setQuery={(newQuery) => updateURL(newQuery, filters, 1)}
          handleSearch={() => updateURL(query, filters, 1)}
        />
      </Grid>
      <Grid item xs={12} sx={{ paddingTop: ".5em!important" }}>
        {selectedFilters.map((filter, index) => (
          <Chip
            key={index}
            label={
              <span>
                {filter.type.toLowerCase()}:{" "}
                <i>
                  {filter.type === "Time"
                    ? `< ${filter.value} minutes`
                    : filter.value}
                </i>
              </span>
            }
            onDelete={() => {
              if (filter.type === "Time") {
                // For time, we just reset the time filter
                updateFilters(filter.type, ""); // Clear the time filter
              } else {
                // For books and authors, we remove the specific filter
                updateFilters(filter.type, filter.value, true); // Pass true to indicate deletion
              }
            }}
            sx={{ borderRadius: ".5em", margin: "0 .5em .5em 0", fontFamily:'IBM Plex Mono, monospace', fontSize:'12px' }}
          />
        ))}

        <Button
          size="small"
          style={{
            marginLeft: "auto",
            display: "block",
            textTransform: "none",
            fontFamily:'IBM Plex Mono, monospace'
          }}
          onClick={() => setShowFilters(!showFilters)}
        >
          + filter
        </Button>
      </Grid>
      {showFilters && (
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ width: "80%" }}>
            <DynamicFilter
              updateFilters={updateFilters}
              selectedFilters={selectedFilters}
            />
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        <RecipeList
          loading={loading}
          errorMessage={errorMessage}
          recipes={showRecipes ? recipes : []}
          hasSearched={hasSearched}
          user={user}
          showResultsText={true}
          totalCount={totalCount}
        />
      </Grid>
      {recipes.length > 0 && (
        <div
          style={{
            position: "fixed",
            bottom: "0",
            left: "0",
            right: "0",
            backgroundColor: "white",
            padding: "10px 0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <Pagination
            count={Math.ceil(totalCount / ITEMS_PER_PAGE)}
            page={currentPage}
            onChange={handlePageChange}
            color="#000000"
            sx={{
              fontFamily: 'IBM Plex Mono',
              '& .MuiPaginationItem-text': {
                fontFamily: 'IBM Plex Mono',
              }
            }}
          />
        </div>
      )}
    </Grid>
  );
}
export default SearchRecipes;
