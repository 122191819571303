import React, { useState, useEffect } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import FavoriteRecipes from './FavoriteRecipes';
import supabase from '../supabaseClient';
import {Button, TextField, Grid, Typography} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

function ListOverview({ lists, onNewListCreated, user }) {
    const [newListName, setNewListName] = useState('');

    const handleCreateList = async () => {
        if (newListName.trim() === '') return;

        const { data, error } = await supabase
            .from('saved_lists')
            .insert([{ list_name: newListName, user_id: user.id }])
            .select()
        
            if (error) {
                console.error('Error creating new list:', error);
            } else if (data && data.length > 0) {
                setNewListName('');
                onNewListCreated(data[0]);
            } 
        }
        const cardStyle = {
            border: '1px solid rgb(204, 204, 204)',
            padding: '1em',
            margin: '1em 0',
            borderRadius: '.5em',
            // boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            cursor: 'pointer',
            transition: 'box-shadow 0.3s',
            textDecoration: 'none'
        };
    
        const cardTitleStyle = {
            fontSize: '1.2em',
            marginBottom: '5px',
            color:'black'
        };
    
        return (
            <Grid>
            <div style={{ marginTop: '1em' }}>
                <Typography variant="" sx={{ fontSize: '1.3rem', fontFamily: 'IBM Plex Mono', cursor: 'pointer' }}>
                    {'lists'}
                </Typography>
            </div>                <div>
                    {lists.map(list => (
                        <Link to={`/lists/${list.list_name}`} key={list.list_id} style={{ textDecoration: 'none' }}>
                            <div style={cardStyle}>
                                <div style={cardTitleStyle}>
                                    <Typography sx={{fontFamily: 'IBM Plex Mono'}}>{list.list_name}</Typography>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
 
            <div style={{ display: 'flex', flexDirection: 'row', gap: '.5em', marginTop:'.5em'}}>
                <TextField 
                    value={newListName} 
                    onChange={e => setNewListName(e.target.value)} 
                    placeholder="new list name" 
                    variant="outlined"
                    fullWidth
                    sx={{
                        fontFamily: "'IBM Plex Mono', monospace",
                "& .MuiOutlinedInput-root": {
                  fontFamily: "'IBM Plex Mono', monospace",
                },
                    }}
                    InputProps={{
                        style: {
                            height: '3em',
                        }
                    }}
                />

                <Button variant="contained" color="primary" onClick={handleCreateList} sx={{backgroundColor: 'white', boxShadow: 'none', border: '1px solid #ccc'}}>
                    
                <AddIcon style={{color:'#aaa'}}/>
                </Button>
            </div>
        </Grid>
        );
    }
    
    function ListsPage({ user }) {
        const [lists, setLists] = useState([]);
    
        useEffect(() => {
            const fetchLists = async () => {
                const { data } = await supabase
                    .from('saved_lists')
                    .select('*')
                    .eq('user_id', user.id);
                setLists(data || []);
            };
    
            if (user) fetchLists();
        }, [user]);
    
        const addNewList = (newList) => {
            setLists(prev => [...prev, newList]);
        };
    
        return (
        <Routes>
            <Route path="/" element={<ListOverview lists={lists} onNewListCreated={addNewList} user={user}/>} />
            {lists.map(list => (
                <Route key={list.list_id} path={`/${list.list_name}`} element={<FavoriteRecipes user={user} listId={list.list_id} listName={list.list_name} />} />
            ))}
        </Routes>
        );
    }
    
    export default ListsPage;