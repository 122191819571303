import thingsLogo from './assets/things-3-logo.png';
import { Button, Typography } from '@mui/material';

export function SendToThings({ listItems }) {
    // Function to create the Things URL
    function createThingsURL(items) {
        const encodedItems = items.map(item => encodeURIComponent(item.name));
        const baseUrl = "things:///add?title=Shopping List&show-quick-entry=true&checklist-items=";
        return baseUrl + encodedItems.join('%0A');
    }

    // Handler for button click
    const handleSendToThings = () => {
        const url = createThingsURL(listItems);
        window.open(url, '_blank');
    };

    // Inline styles

    const logoStyle = {
        width: '1.75em',
        height: '1.75em',
        marginRight: '.2em',
        borderRadius: '.1em'
        }
    // State for hover effect

    return (
        <Button
            // style={hover ? buttonHoverStyle : buttonStyle}
            sx={{
                margin:'0 0 1em 0', 
                color: '#333',
                padding:'.5em .5em',
                // border: '0.1em solid #ccc', 
                fontFamily: 'Montserrat', 
                // textTransform:'none'

            }}
            onClick={handleSendToThings}
        >
            <img
                src={thingsLogo}
                alt="Things Logo"
                style={logoStyle} />
        <Typography variant='' sx={{fontSize:'.95rem', fontWeight:'500', textTransform:'none'}}>send to Things</Typography>
        </Button>
    );
}
