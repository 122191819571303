import React, { useState, useEffect, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import {
  Container,
  Typography,
  CircularProgress,
  Box,
  IconButton,
} from "@mui/material";
import { ReactReaderStyle } from "react-reader";
import supabase from "../supabaseClient";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Fab from "@mui/material/Fab";
import ExtendedReactReader from "./CustomReactReader";
import FontSettingsMenu from "./FontSettingsMenu";

function BookDetail() {
  const { bookName: encodedBookName } = useParams();
  const bookName = decodeURIComponent(encodedBookName); // Decode the book name
  const locationQuery = useLocation(); // Hook to access URL parameters
  const [book, setBook] = useState(null);
  const [loading, setLoading] = useState(true);
  const [location, setLocation] = useState(
    localStorage.getItem(`book-progress-${bookName}`) || 0
  ); // Restore progress specific to this book
  const [saveProgress, setSaveProgress] = useState(false); // Control saving progress to localStorage
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const renditionRef = useRef(null);
  const [navigationHistory, setNavigationHistory] = useState([]); // Stack to store user-initiated section navigation history
  const [isUserInitiated, setIsUserInitiated] = useState(false); // Track whether the current navigation is user-initiated
  const [, /*currentPage*/ setCurrentPage] = useState(null); // To store the current page number
  const [currentPercentage, setCurrentPercentage] = useState(null); // Track current percentage
  const [fontSize, setFontSize] = useState(100); // State for font size

  // Extract query parameters from URL
  const searchParams = new URLSearchParams(locationQuery.search);
  const spineLocation = searchParams.get("spineLocation") || null;
  const query = searchParams.get("query") || null;

  const [hasNavigated, setHasNavigated] = useState(false); // Track if we have navigated
  const [isTocOpen, setIsTocOpen] = useState(false);

  const handleTocToggle = (isOpen) => {
    setIsTocOpen(isOpen);
  };

  useEffect(() => {
    const fetchBookDetail = async () => {
      const { data, error } = await supabase
        .from("books")
        .select("*")
        .eq("name", bookName)
        .single();

      if (error) {
        console.error("Error fetching book details:", error);
      } else {
        setBook(data);
      }
      setLoading(false);
    };

    fetchBookDetail();
  }, [bookName]);

  const handleBackToPrevious = () => {
    if (navigationHistory.length > 0 && renditionRef.current) {
      const lastLocation = navigationHistory[navigationHistory.length - 1];
      setNavigationHistory((prev) => prev.slice(0, prev.length - 1)); // Remove the last location from history
      renditionRef.current.display(lastLocation.cfi); // Use the stored CFI to navigate back
    }
  };

  useEffect(() => {
    if (renditionRef.current) {
      renditionRef.current.themes.fontSize(`${fontSize}%`);
    }
  }, [fontSize]);

  // useEffect(() => {
  //     console.log("Updated currentPage: ", navigationHistory);
  // }, [navigationHistory]);

  const locationChanged = (epubcfi) => {
    if (saveProgress) {
      setLocation(epubcfi);
      localStorage.setItem(`book-progress-${bookName}`, epubcfi); // Save progress specific to this book
    }
  };

  const handlePrev = () => {
    if (renditionRef.current) {
      renditionRef.current.prev();
    }
  };

  const handleNext = () => {
    if (renditionRef.current) {
      renditionRef.current.next();
    }
  };

  const handleTouchStart = (e, direction) => {
    const iframe = document.querySelector("iframe");

    if (
      iframe &&
      iframe.contentWindow &&
      e.target.ownerDocument === iframe.contentWindow.document
    ) {
      return;
    }

    if (direction === "prev") {
      handlePrev();
    } else if (direction === "next") {
      handleNext();
    }
  };

  const attachAnchorTagClickListener = () => {
    const iframe = document.querySelector("iframe");
    if (iframe && iframe.contentWindow && iframe.contentDocument) {
      const doc = iframe.contentDocument || iframe.contentWindow.document;

      // Attach event listener to all anchor tags within the iframe
      const anchorTags = doc.querySelectorAll("a");
      anchorTags.forEach((anchor) => {
        anchor.addEventListener("click", (e) => {
          e.preventDefault(); // Prevent default link behavior
          const href = anchor.getAttribute("href");
          if (href && renditionRef.current) {
            const currentLocation = renditionRef.current.location.start.cfi; // Get current page location (before navigation)

            setCurrentPage((prevPage) => {
              setNavigationHistory((prev) => [
                ...prev,
                { cfi: currentLocation, page: prevPage },
              ]); // Store the current location and page number
              return prevPage; // No change to current page value
            });
            setIsUserInitiated(true); // Mark navigation as user-initiated
            // renditionRef.current.display(href); // Navigate to the href
          }
        });
        });
    }
  };

  const highlightText = (rendition, section, searchText) => {
    const iframe = document.querySelector("iframe");

    if (iframe) {
      const doc = iframe.contentDocument || iframe.contentWindow.document;
      if (doc) {
        const body = doc.body;

        // Utility to normalize text: lowercase, remove punctuation, replace abbreviations
        function normalizeText(text) {
          return text
            .toLowerCase()
            .replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, "") // Updated code
            .replace(/\bw\/\b/g, "with") // Replace abbreviations
            .trim();
        }

        // Levenshtein distance function
        function levenshtein(a, b) {
          const matrix = [];

          // Increment along the first column of each row
          for (let i = 0; i <= b.length; i++) {
            matrix[i] = [i];
          }

          // Increment each column in the first row
          for (let j = 0; j <= a.length; j++) {
            matrix[0][j] = j;
          }

          // Fill in the rest of the matrix
          for (let i = 1; i <= b.length; i++) {
            for (let j = 1; j <= a.length; j++) {
              if (b.charAt(i - 1) === a.charAt(j - 1)) {
                matrix[i][j] = matrix[i - 1][j - 1];
              } else {
                matrix[i][j] = Math.min(
                  matrix[i - 1][j - 1] + 1, // substitution
                  matrix[i][j - 1] + 1, // insertion
                  matrix[i - 1][j] + 1 // deletion
                );
              }
            }
          }

          return matrix[b.length][a.length];
        }

        // Function to find text node with fuzzy matching
        function findTextNode(node, searchString) {
          if (node.nodeType === Node.TEXT_NODE) {
            const originalText = node.textContent; // Keep the original text for range setting
            const normalizedText = normalizeText(originalText);
            const normalizedSearch = normalizeText(searchString);

            const distance = levenshtein(normalizedText, normalizedSearch);
            const threshold =
              Math.max(normalizedText.length, normalizedSearch.length) * 0.4; // Allow 30% difference

            if (distance <= threshold) {
              return { node, index: normalizedText.indexOf(normalizedSearch) }; // Return the match position within normalized text
            }
          } else {
            for (let child of node.childNodes) {
              let result = findTextNode(child, searchString);
              if (result) return result;
            }
          }
          return null;
        }

        const { node: foundTextNode, index: startIndex } =
          findTextNode(body, searchText) || {};

        if (foundTextNode) {
          // Ensure the indices are valid with respect to the actual node text
          const originalTextLength = foundTextNode.textContent.length;

          let safeStartIndex = Math.max(0, startIndex);
          let safeEndIndex = Math.min(
            safeStartIndex + searchText.length,
            originalTextLength
          );

          const range = doc.createRange();

          // Ensure the range indices are within bounds
          if (safeEndIndex <= originalTextLength) {
            range.setStart(foundTextNode, safeStartIndex);
            range.setEnd(foundTextNode, safeEndIndex);

            const cfi = section.cfiFromRange(range);

            // Ensure we navigate to the exact location of the CFI
            rendition.display(cfi).then(() => {
              rendition.annotations.add(
                "highlight",
                cfi,
                {},
                null,
                "highlight",
                {
                  backgroundColor: "yellow",
                  border: "1px solid black",
                }
              );
              console.log("Navigated to and highlighted text at CFI:", cfi);
            });
          } else {
            console.error(
              `Invalid range indices: startIndex = ${safeStartIndex}, endIndex = ${safeEndIndex}, original text length = ${originalTextLength}`
            );
          }
        } else {
          console.log("Text not found in the current section.");
        }
      }
    }
  };

  const getRendition = async (rendition) => {
    renditionRef.current = rendition;
    rendition.themes.fontSize(`${fontSize}%`);


    const generateLocationsAsync = async () => {
      // Generate locations asynchronously
      await rendition.book.locations.generate(1024); // You can adjust the chunk size if needed
      const locations = rendition.book.locations.save();
      localStorage.setItem(
        `book-locations-${bookName}`,
        JSON.stringify(locations)
      );
      console.log("Generated and saved locations asynchronously");
    };

    const savedLocations = localStorage.getItem(`book-locations-${bookName}`);

    if (savedLocations) {
      // Load locations from localStorage
      const locations = JSON.parse(savedLocations);
      rendition.book.locations.load(locations);
      console.log("Loaded locations from localStorage");
    } else {
      // Start generating locations in the background
      console.log("Generating locations in the background...");
      generateLocationsAsync(); // Run async generation without blocking the user
    }

    let dynamicDir = ""; // Variable to store the dynamically detected directory

    // Function to dynamically prepend the correct directory
    function normalizeLocation(location) {
      if (!location) return location; // Return null or undefined as is

      // Check if the location is a CFI (CFIs typically start with 'epubcfi(')
      if (location.startsWith("epubcfi(")) return location; // Return the CFI as-is

      const baseLocation = location.split("#")[0]; // Remove fragment if it exists

      // Prepend the dynamically detected directory if it's not already present
      if (
        !baseLocation.startsWith(dynamicDir) &&
        validateLocation(dynamicDir + baseLocation)
      ) {
        // console.log('Using dynamic directory:' + dynamicDir+baseLocation)
        return dynamicDir + baseLocation;
      }

      // console.log('Using base directory:' + baseLocation)

      return baseLocation; // Return the normalized location
    }

    // Function to process TOC items recursively
    function processTocItems(items) {
      items.forEach((item) => {
        if (item.href.includes("#")) {
          item.href = item.href.split("#")[0]; // Remove fragment
        }

        // Ensure dynamicDir is prepended if not already present
        if (!item.href.startsWith(dynamicDir)) {
          item.href = dynamicDir + item.href;
        }

        // Recursively process subitems if they exist
        if (item.subitems && item.subitems.length > 0) {
          processTocItems(item.subitems);
        }
      });
    }

    // Function to normalize the spine location
    function normalizeSpineLocation(spineLocation, rendition) {
      if (!spineLocation) return spineLocation;

      const filename = spineLocation.split("/").pop();

      if (validateLocation(spineLocation, rendition)) {
        return spineLocation;
      }

      const normalizedLocation = dynamicDir ? dynamicDir + filename : filename;

      return normalizedLocation;
    }

    // Function to validate if the location is valid for the current book
    const validateLocation = (location, rendition) => {
      if (!location || !rendition.book.spine) return false;
      try {
        const section = rendition.book.spine.get(location);
        return !!section;
      } catch (err) {
        return false; // Invalid location
      }
    };

    // Function to proceed with rendering once everything is ready
    const proceedWithRendering = () => {
      const validLocation = validateLocation(location, rendition);

      if (!hasNavigated) {
        if (spineLocation) {
          const normalizedSpineLocation = normalizeSpineLocation(
            spineLocation,
            rendition
          );
          rendition
            .display(normalizedSpineLocation)
            .then(() => {
              if (query) {
                highlightText(
                  rendition,
                  rendition.book.spine.get(normalizedSpineLocation),
                  query
                );
              }
              setHasNavigated(true);
              setIsUserInitiated(false); // Reset after auto navigation
            })
            .catch((err) => {
              console.error(
                `Error displaying the spine location "${normalizedSpineLocation}":`,
                err
              );
              rendition.display(
                validLocation ? normalizeLocation(location) : 1
              ); // Fallback
            });
        } else {
          rendition.display(validLocation ? normalizeLocation(location) : 1);
          setHasNavigated(true);
          setIsUserInitiated(false); // Reset after auto navigation
        }
      } else {
        rendition.display(validLocation ? normalizeLocation(location) : 1);
      }

      setSaveProgress(true); // Enable saving progress
    };

    // Dynamically detect the directory from the first spine item
    const spine = await rendition.book.loaded.spine;

    if (spine && spine.spineItems.length > 0) {
        const firstItem = spine.spineItems[1];
        
        // Split the href by "/" and remove the last part (the filename)
        const hrefParts = firstItem.href.split("/");
        
        // Remove the last part (which is the file name)
        hrefParts.pop();
        
        // Rejoin the remaining parts and append "/"
        dynamicDir = hrefParts.length > 0 ? hrefParts.join("/") + "/" : "";
        
        // console.log("Detected Dynamic Directory:", dynamicDir); // Log the dynamically detected directory
    }

    rendition.on("relocated", () => {
      const currentLocation = rendition.currentLocation().start;

      const percentage =
        currentLocation.percentage > 0
          ? Math.round(currentLocation.percentage * 100)
          : null;

      setCurrentPercentage(percentage);

      let chapterLabel = null;

      // Helper function to normalize the CFI
      function getCFIIndex(cfi) {
        // Split the CFI by '/' and return the second item
        const parts = cfi.split("/");
        return Number(parts[2]); // Assuming the second item is always in index 2
      }

      // Recursive function to search for the matching chapter in TOC and its subitems
      function findChapterInTOC(tocItems) {
        let closestMatch = null;
        let closestMatchCFI = -Infinity; // Start with the lowest possible value

        const currentCFIBase = getCFIIndex(
          rendition.book.spine.get(normalizeLocation(currentLocation.href))
            .cfiBase
        );

        function searchToc(items) {
          for (let item of items) {
            const itemCFIBase = getCFIIndex(
              rendition.book.spine.get(normalizeLocation(item.href)).cfiBase
            );
            if (!itemCFIBase) continue;

            if (
              itemCFIBase <= currentCFIBase &&
              itemCFIBase > closestMatchCFI
            ) {
              // If the item's CFI is less than or equal to the current section's CFI but greater than the last closest match, update
              closestMatch = item;
              closestMatchCFI = itemCFIBase;
            }

            // Recursively search in subitems, if available
            if (item.subitems && item.subitems.length > 0) {
              searchToc(item.subitems);
            }
          }
        }

        // Call the recursive search function
        searchToc(tocItems);

        // Set the label of the closest match if found
        if (closestMatch) {
          chapterLabel = closestMatch.label.trim();
        }
      }

      // Call the recursive function with the TOC
      findChapterInTOC(rendition.book.navigation.toc);

      // Set the current page, including the chapter if found
      setCurrentPage({
        percentage: percentage,
        chapterLabel: chapterLabel ? chapterLabel : "",
      });
    });

    // Hook into the TOC to normalize links after rendering
    rendition.on("rendered", () => {
      const tocItems = rendition.book.navigation.toc;
      processTocItems(tocItems); // Process TOC items to normalize href
    });

    rendition.on("rendered", (section) => {
      attachAnchorTagClickListener();

      if (isUserInitiated && section) {
        const currentLocation = section.cfiBase; // Capture current section CFI
        setNavigationHistory((prev) => [...prev, currentLocation]); // Push current location to history
        setIsUserInitiated(false); // Reset after handling user-initiated event
      }
    });

    // Proceed with rendering after dynamicDir is set
    proceedWithRendering();
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!book) {
    return (
      <Container>
        <Typography variant="h4">Book not found</Typography>
      </Container>
    );
  }

  const customReaderStyles = {
    ...ReactReaderStyle,
    container: {
      ...ReactReaderStyle.container,
      border: "1px solid #ccc",
      borderRadius: ".5em",
    },
    arrow: {
      display: "none", // Hide default arrows
    },
    arrowHover: {
      display: "none", // Hide default arrows on hover
    },
    readerArea: {
      ...ReactReaderStyle.readerArea,
      borderRadius: ".5em",
    },
    reader: {
      ...ReactReaderStyle.reader,
      inset: isMobile ? "30px 5px 0 5px" : "50px 50px 20px",
      marginBottom: "1em",
    },
    tocArea: {
      ...ReactReaderStyle.tocArea,
      maxHeight: "100%",
      borderBottomRightRadius: ".5em",
      borderRight: "1px solid #ccc",
      borderBottom: "1px solid #ccc",
    },
  };

  return (
    <Container>
      <Typography variant="h5">{book.name}</Typography>
      <Typography variant="subtitle1" color="textSecondary">
        {book.author ? `by ${book.author}` : "Unknown author"}
      </Typography>
  
      <div
        style={{
          height: "90vh",
          marginBottom: "20px",
          marginTop: "20px",
          position: "relative",
        }}
      >
        {book.path && (
          <ExtendedReactReader
            onTocToggle={handleTocToggle}
            location={location}
            locationChanged={locationChanged}
            url={`https://reciply.s3.us-east-2.amazonaws.com/compressed-books/${encodeURI(
              book.path
            )}`}
            getRendition={getRendition}
            readerStyles={customReaderStyles}
            isRTL={isMobile}
            isMobile={isMobile}
            epubOptions={{
              allowPopups: true,
              allowScriptedContent: true,
            }}
          />
        )}
  
        {!isTocOpen && (
          <>
            <Box
              position="absolute"
              top="10px"
              right="10px"
              zIndex={2}
              borderRadius="5px"
              display="flex"
              alignItems="center"
            >
              {/* Font Size Controls */}
              <FontSettingsMenu fontSize={fontSize} setFontSize={setFontSize} />
              {currentPercentage !== null && currentPercentage > 0 && (
                <Typography
                  variant="body2"
                  color="gray"
                  style={{ marginRight: "10px", marginLeft: ".75em" }}
                >
                  {`${currentPercentage}%`}
                </Typography>
              )}
            </Box>
  
            {navigationHistory.length > 0 && (
              <Box
                display="flex"
                alignItems="center"
                position="absolute"
                bottom="10px"
                left="10px"
                zIndex={1}
                style={{ cursor: "pointer", maxWidth: "90%" }}
                onClick={handleBackToPrevious}
              >
                <Fab
                  color="#ccc"
                  style={{
                    marginRight: "6px",
                    boxShadow: "none",
                    width: "1.5em",
                    height: "1.5em",
                    minHeight: "unset",
                    flexShrink: 0,
                  }}
                >
                  <ArrowBackIcon style={{ fontSize: "16px", color: "#999" }} />
                </Fab>
  
                <Box
                  display="flex"
                  justifyContent="space-between"
                  flexGrow={1}
                  maxWidth="95%"
                >
                  <Typography
                    variant="caption"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      marginRight: "8px",
                      flexGrow: 1,
                      maxWidth: "100%",
                      color: "gray",
                    }}
                  >
                    {navigationHistory[navigationHistory.length - 1].page
                      .chapterLabel || "back to previous"}
                  </Typography>
  
                  <Typography
                    variant="caption"
                    style={{
                      whiteSpace: "nowrap",
                      flexShrink: 0,
                      color: "gray",
                    }}
                  >
                    {navigationHistory[navigationHistory.length - 1].page
                      .percentage
                      ? `(${navigationHistory[navigationHistory.length - 1].page.percentage}%)`
                      : ""}
                  </Typography>
                </Box>
              </Box>
            )}
  
            {/* Render page controls only when TOC is not open */}
            {!isMobile ? (
              <>
                <IconButton
                  onClick={handlePrev}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "10px",
                    transform: "translateY(-50%)",
                    zIndex: 1,
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
                <IconButton
                  onClick={handleNext}
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)",
                    zIndex: 1,
                  }}
                >
                  <ArrowForwardIcon />
                </IconButton>
              </>
            ) : (
              <>
                <div
                  onTouchStart={(e) => handleTouchStart(e, "prev")}
                  style={{
                    position: "absolute",
                    top: 50,
                    left: 0,
                    width: "20px",
                    height: "90%",
                    zIndex: 1,
                    opacity: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                  }}
                />
                <div
                  onTouchStart={(e) => handleTouchStart(e, "next")}
                  style={{
                    position: "absolute",
                    top: 50,
                    right: 0,
                    width: "20px",
                    height: "90%",
                    zIndex: 1,
                    opacity: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                  }}
                />
              </>
            )}
          </>
        )}
      </div>
    </Container>
  );
}  

export default BookDetail;
