import React, { useState, useEffect } from "react";
import { Grid, Button, Typography } from "@mui/material";
import RecipeList from "../Recipes/RecipeList";
import supabase from "../supabaseClient";
import { CircularProgress } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { ShoppingList } from "./ShoppingList";
import { CustomMarkdown } from "./CustomMarkdown";

function FavoriteRecipes({ user, listId, listName }) {
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [favoriteRecipes, setFavoriteRecipes] = useState([]);
  // const [shoppingList, setShoppingList] = useState('')
  const [shoppingListLoading, setShoppingListLoading] = useState(false);
  const [markdownContent, setMarkdownContent] = useState("");
  const [isListExist, setIsListExist] = useState(false);

  useEffect(() => {
    if (!user) {
      setLoading(false);
      return;
    }

    const fetchFavoriteRecipes = async () => {
      try {
        const { data: recipesData, error: recipesError } = await supabase
          .from("lists_recipes")
          .select(
            `
                        saved_lists (list_name),
                        recipes (
                            *,
                            books (
                                name,
                                author
                            )
                        )
                    `
          )
          .eq("list_id", listId);

        if (recipesError || !recipesData) {
          throw new Error("Could not fetch favorite recipes.");
        }

        // Formatting the data as required
        const formattedData = recipesData.map((item) => ({
          list_name: item.saved_lists.list_name,
          id: item.recipes.id,
          title: item.recipes.title,
          ingredients: item.recipes.ingredients,
          instructions: item.recipes.instructions,
          yield: item.recipes.yield,
          time: item.recipes.time,
          inactive_time: item.recipes.inactive_time,
          path: item.recipes.path,
          image_url: item.recipes.image_url,
          book_name: item.recipes.books ? item.recipes.books.name : null,
          book_author: item.recipes.books ? item.recipes.books.author : null,
        }));

        setFavoriteRecipes(formattedData);
        setLoading(false);
      } catch (error) {
        setErrorMessage(error.message);
        setLoading(false);
      }
    };

    fetchFavoriteRecipes();
  }, [user, listId]);

  useEffect(() => {
    const fetchShoppingList = async () => {
      try {
        const { data: listData, error: listError } = await supabase
          .from("lists_shopping_list")
          .select("ingredient_json")
          .eq("list_id", listId);

        if (listError) {
          throw new Error("Could not fetch shopping list content.");
        }

        if (listData && listData.length > 0) {
          setIsListExist(true);
          setMarkdownContent(listData[0].ingredient_json);
        } else {
          setIsListExist(false);
        }
      } catch (error) {
        console.error("Error fetching shopping list content:", error);
      }
    };

    if (listId) {
      fetchShoppingList();
    }
  }, [listId]);

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const generateShoppingList = async () => {
    setMarkdownContent("");
    setShoppingListLoading(true);

    const allIngredients = favoriteRecipes.flatMap((recipe) =>
      recipe.ingredients.map((ing) => ({
        name: ing.name,
        quantity: ing.quantity,
      }))
    );

    try {
      // Update ingredient_json column to null for the given listId
      const { error: updateError } = await supabase
        .from("lists_shopping_list")
        .update({ ingredient_json: null })
        .eq("list_id", listId);

      if (updateError) {
        throw new Error("Could not update ingredient_json to null.");
      }

      const response = await fetch(`${SERVER_URL}/api/lists/create-list`, {
        method: "POST",
        body: JSON.stringify({ ingredients: allIngredients, list_id: listId }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const { requestId } = await response.json();

      // Start an SSE connection using the requestId
      const es = new EventSource(
        `${SERVER_URL}/api/lists/create-list/${requestId}`
      );

      // Handle incoming messages (updates) from the server
      es.onmessage = (event) => {
        const content = JSON.parse(event.data);
        setMarkdownContent((prevContent) => prevContent + content);
      };

      // Handle errors
      es.onerror = () => {
        es.close();
        setIsListExist(true);
        setShoppingListLoading(false);
      };
    } catch (error) {
      console.error("Failed to send ingredients:", error);
      setShoppingListLoading(false);
    }
  };

  return (
    <Grid>
      <div style={{ marginTop: "1em" }}>
        <Typography
          variant=""
          sx={{ fontSize: "1.3rem", fontFamily: "IBM Plex Mono" }}
        >
          {listName}
        </Typography>
      </div>
      <div>
        {loading ? (
          <p>Loading...</p>
        ) : errorMessage ? (
          <p>{errorMessage}</p>
        ) : favoriteRecipes.length === 0 ? (
          <p>No recipes saved.</p>
        ) : (
          <RecipeList
            loading={loading}
            recipes={favoriteRecipes}
            user={user}
            showResultsText={true}
            totalCount={favoriteRecipes.length}
            isFavorited={true}
          />
        )}
        <div style={{ display: "flex", alignItems: "center" }}>
          {isListExist ? (
            <Button
              variant="contained"
              color="secondary"
              onClick={generateShoppingList}
              disabled={true}
              sx={{ marginBottom: "1em", fontFamily: 'IBM Plex Mono'}}
            >
              Generate shopping list
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={generateShoppingList}
              sx={{ marginBottom: "1em" }}
              disabled={shoppingListLoading}
            >
              Generate shopping list
            </Button>
          )}
          <span style={{ marginLeft: "10px" }}>
            {shoppingListLoading ? (
              <CircularProgress size={24} />
            ) : (
              isListExist && (
                <RefreshIcon
                  style={{ cursor: "pointer" }}
                  onClick={generateShoppingList}
                />
              )
            )}
          </span>
        </div>
        <div>
          {shoppingListLoading && markdownContent ? (
            <CustomMarkdown
              content={markdownContent}
              setMarkdownContent={setMarkdownContent}
              disabled={shoppingListLoading} // <-- Add this line
            />
          ) : (
            <ShoppingList listId={listId} />
          )}
        </div>
      </div>
    </Grid>
  );
}

export default FavoriteRecipes;
